// src/components/AxiosWrapper.js
import axios from 'axios';

const BASE_API = process.env.VUE_APP_API_BASE
const BACKUP_API = 'https://gab16.azurewebsites.net/api';

export default {
  async SendGetRequest(endpoint, retries = 1) {
    let attempt = 0;

    while (attempt <= retries) {
      try {
        // Attempt GET request to the primary API
        const response = await axios.get(`${BASE_API}/${endpoint}`);
        return response.data;
      } catch (error) {
        attempt++;
        console.error(`GET request attempt ${attempt} failed for endpoint ${endpoint}:`, error);

        // If all retries failed, attempt backup API
        if (attempt > retries) {
          try {
            console.log(`Switching to backup API for GET request at endpoint: ${endpoint}`);
            const response = await axios.get(`${BACKUP_API}/${endpoint}`);
            return response.data;
          } catch (backupError) {
            console.error(`Backup API GET request failed for endpoint ${endpoint}:`, backupError);
            throw backupError; // Re-throw if backup fails
          }
        }
      }
    }
  },

  async SendPostRequest(endpoint, payload, retries = 1) {
    let attempt = 0;

    while (attempt <= retries) {
      try {
        // Attempt POST request to the primary API
        const response = await axios.post(`${BASE_API}/${endpoint}`, payload);
        return response.data;
      } catch (error) {
        attempt++;
        console.error(`POST request attempt ${attempt} failed for endpoint ${endpoint}:`, error);

        // If all retries failed, attempt backup API
        if (attempt > retries) {
          try {
            console.log(`Switching to backup API for POST request at endpoint: ${endpoint}`);
            const response = await axios.post(`${BACKUP_API}/${endpoint}`, payload);
            return response.data;
          } catch (backupError) {
            console.error(`Backup API POST request failed for endpoint ${endpoint}:`, backupError);
            throw backupError; // Re-throw if backup fails
          }
        }
      }
    }
  },
};
