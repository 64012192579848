<template>
  <div>
    <h1>Demos</h1>
    <p>This page showcases various demos. Here, you'll find interactive examples and tech showcases.</p>

    <!-- Display each demo item -->
    <div v-if="demos.length > 0">
      <ul>
        <li v-for="(demo, index) in demos" :key="index" @click="goToDemo(demo.url)" style="cursor: pointer;">
          <h3>{{ demo.title }}</h3>
          <p>{{ demo.description }}</p>
        </li>
      </ul>
    </div>

    <p v-else>Loading demos...</p>
  </div>
</template>

<script>
export default {
  name: "DemosPage",
  data() {
    return {
      demos: []
    };
  },
  created() {
    this.loadDemos();
  },
  methods: {
    async loadDemos() {
      try {
        const response = await fetch("/demos.json");
        if (!response.ok) throw new Error("Failed to load demos");
        this.demos = await response.json();
      } catch (error) {
        console.error("Error loading demos:", error);
      }
    },
    goToDemo(url) {
      this.$router.push(url);
    }
  }
};
</script>

<style scoped>
/* Optional styling */
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
